import "./Forms.scss";

import { useCallback } from "react";
import { FormHeader } from "./FormHeader";
import { useForm } from "react-hook-form";

interface Props {
  setAuthorized: (success: boolean) => void;
}

const LOGIN_URL = "/api/login";

type FormValues = {
  partnerId: string;
  password: string;
};

export const AuthForm = ({ setAuthorized }: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = useCallback(
    async ({ partnerId, password }: FormValues) => {
      const login = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          partnerId,
          password,
        }),
      });
      if (login.status === 200) {
        const asJson = await login.json();
        sessionStorage.setItem("client_id", asJson.client_id);
        setAuthorized(true);
      } else {
        // TODO: Display this in a banner or some better way
        alert("Login unsuccessful");
      }
    },
    [setAuthorized]
  );

  return (
    <div className="form-wrapper">
      <FormHeader title="Set up an account for your client today." />

      <form
        className="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="form__row">
          <label className="form__label">Partner ID*</label>
          <input
            className="form__input"
            {...register("partnerId", { required: "This field is required." })}
          />
          {errors.partnerId && (
            <span className="form__error">{errors.partnerId.message}</span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">Password*</label>
          <input
            className="form__input"
            type="password"
            {...register("password", { required: "This field is required." })}
          />
          {errors.password && (
            <span className="form__error">{errors.password.message}</span>
          )}
        </div>

        <button className="form__button" type="submit">
          Next
        </button>
      </form>
    </div>
  );
};
