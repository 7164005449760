import bsiLogo from "../../assets/images/Frame 6009 (1).png";

interface Props {
  title: string;
}

export const FormHeader = ({ title }: Props): JSX.Element => {
  return (
    <>
      <img
        className="form-wrapper__image"
        src={bsiLogo}
        alt="BrainStorm, Inc."
      />

      <h2 className="form-wrapper__title">{title}</h2>
    </>
  );
};
