import "./Forms.scss";

import { useCallback } from "react";
import { FormHeader } from "./FormHeader";
import { useForm } from "react-hook-form";
import { CreateAccount } from "../../services/requests";
import { AxiosError } from "axios";

type FormValues = {
  companyName: string;
  organizationSize: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
};

export const CreationForm = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    setError,
  } = useForm<FormValues>();

  const onSubmit = useCallback(
    async ({
      companyName,
      organizationSize,
      firstName,
      lastName,
      jobTitle,
      email,
    }: FormValues) => {
      let parsedOrgSize: number;
      try {
        parsedOrgSize = parseInt(organizationSize.replaceAll(",", ""));
        if (parsedOrgSize <= 0) {
          setError("organizationSize", {
            message: "Organization size must be greater than 0",
          });
          return;
        }
        if (parsedOrgSize >= 5_000_000) {
          setError("organizationSize", {
            message: "Organization size must be less than 5,000,000",
          });
          return;
        }
      } catch (err) {
        alert(
          "Failed to read organization size as number. Try updating that value."
        );
        return;
      }

      try {
        alert("Beginning account creation. This could take a few minutes.");
        await CreateAccount({
          name: companyName,
          contact: {
            email,
            firstName,
            lastName,
            jobTitle,
          },
          organizationSize: parsedOrgSize,
        });
        reset();
        alert("Account Creation Successful");
      } catch (err) {
        let error = err as AxiosError;
        if (error.response) {
          console.error(error.response.data);
          alert(error.response.data);
        } else {
          console.error(error);
          alert(
            "An unexpected error occured trying to create this account. Please try again later."
          );
        }
      }
    },
    [reset, setError]
  );

  return (
    <div className="form-wrapper">
      <FormHeader title="Account details." />

      <form
        className="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <div className="form__row">
          <label className="form__label">Company name*</label>
          <input
            className="form__input"
            {...register("companyName", {
              required: "This field is required.",
            })}
          />
          {errors.companyName && (
            <span className="form__error">{errors.companyName.message}</span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">Organization size*</label>
          <input
            className="form__input"
            {...register("organizationSize", {
              required: "This field is required.",
              pattern: {
                value: /^(\d+|\d{1,3}(,\d{3})*)?$/i,
                message: "Value is not a valid number.",
              },
            })}
          />
          {errors.organizationSize && (
            <span className="form__error">
              {errors.organizationSize.message}
            </span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">First name*</label>
          <input
            className="form__input"
            {...register("firstName", { required: "This field is required." })}
          />
          {errors.firstName && (
            <span className="form__error">{errors.firstName.message}</span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">Last name*</label>
          <input
            className="form__input"
            {...register("lastName", { required: "This field is required." })}
          />
          {errors.lastName && (
            <span className="form__error">{errors.lastName.message}</span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">Job title*</label>
          <input
            className="form__input"
            {...register("jobTitle", { required: "This field is required." })}
          />
          {errors.jobTitle && (
            <span className="form__error">{errors.jobTitle.message}</span>
          )}
        </div>

        <div className="form__row">
          <label className="form__label">Email*</label>
          <input
            type="email"
            className="form__input"
            {...register("email", {
              required: "This field is required.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {errors.email && (
            <span className="form__error">{errors.email.message}</span>
          )}
        </div>

        <button className="form__button" type="submit" disabled={isSubmitting}>
          {isSubmitting ? <LoadingSpinner /> : "Setup"}
        </button>
      </form>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
