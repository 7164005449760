import { useState } from "react";
import { AuthForm } from "./AuthForm";
import { CreationForm } from "./CreationForm";

export const BaseForm = (): JSX.Element => {
  const [authSuccess, setAuthSuccess] = useState(
    sessionStorage.getItem("client_id") !== null
  );

  return (
    <>
      {authSuccess ? (
        <CreationForm />
      ) : (
        <AuthForm setAuthorized={setAuthSuccess} />
      )}
    </>
  );
};
