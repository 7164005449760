export type WithLink = {
  text: string;
  link: string;
};

export const solutions: WithLink[] = [
  { text: "Box", link: "https://www.brainstorminc.com/box-software-training" },
  { text: "Dropbox", link: "https://www.brainstorminc.com/dropbox" },
  {
    text: "Google Workspace",
    link: "https://www.brainstorminc.com/google-workspace",
  },
  {
    text: "Microsoft 365",
    link: "https://www.brainstorminc.com/microsoft-365-training",
  },
  { text: "Slack", link: "https://www.brainstorminc.com/slack" },
  { text: "Smartsheet", link: "https://www.brainstorminc.com/smartsheet" },
  {
    text: "Threat Defense",
    link: "https://www.brainstorminc.com/threat-defense",
  },
  { text: "Webex", link: "https://www.brainstorminc.com/webex-training" },
  {
    text: "Zoom",
    link: "https://www.brainstorminc.com/zoom-technology-training",
  },
];

export const products: WithLink[] = [
  { text: "QuickHelp", link: "https://www.brainstorminc.com/quickhelp" },
];

export const partners: WithLink[] = [
  {
    text: "Solution provider",
    link: "https://www.brainstorminc.com/partner-program-solution-provider",
  },
  {
    text: "Channel reseller",
    link: "https://www.brainstorminc.com/partner-program-channel-reseller",
  },
  {
    text: "Service provider",
    link: "https://www.brainstorminc.com/partner-program-service-provider",
  },
];

export const resources: WithLink[] = [
  { text: "Blog", link: "https://www.brainstorminc.com/blog" },
  {
    text: "Customers",
    link: "https://www.brainstorminc.com/resources-customer-spotlight",
  },
  {
    text: "eBooks",
    link: "https://www.brainstorminc.com/resources-ebooks?eBook",
  },
  { text: "Guides", link: "https://www.brainstorminc.com/resources?Guide" },
  {
    text: "Infographics",
    link: "https://www.brainstorminc.com/resources-infographics?Infographic",
  },
  { text: "Webinars", link: "https://www.brainstorminc.com/webinars" },
];

export const company: WithLink[] = [
  { text: "About", link: "https://www.brainstorminc.com/about" },
  { text: "Careers", link: "https://www.brainstorminc.com/careers" },
  { text: "Contact us", link: "https://www.brainstorminc.com/contact" },
];
