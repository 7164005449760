import { useEffect } from "react";
import "./App.scss";

import { BaseForm } from "./components/forms/BaseForm";
import { Content } from "./components/content/Content";
import { Footer } from "./components/footer/Footer";

export const App = (): JSX.Element => {
  useEffect(() => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
  }, []);

  return (
    <div className="page">
      <main className="page__main-content">
        <BaseForm />
        <Content />
      </main>

      <Footer />
    </div>
  );
};

export default App;
