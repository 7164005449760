import {
  solutions,
  products,
  partners,
  resources,
  company,
  WithLink,
} from "./FooterValues";

import facebookIcon from "../../assets/images/footer/facebook.png";
import instagramIcon from "../../assets/images/footer/instagram.png";
import linkedinIcon from "../../assets/images/footer/linkedin.png";
import twitterIcon from "../../assets/images/footer/twitter.png";

import "./Footer.scss";

const CreateLinkItem = (linkItem: WithLink): JSX.Element => {
  return (
    <li key={linkItem.text}>
      <a
        className="footer__link"
        href={linkItem.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        {linkItem.text}
      </a>
    </li>
  );
};

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer--top">
          <section className="footer__column">
            <h3 className="footer__sub-header">Solutions</h3>
            <ul>{solutions.map(CreateLinkItem)}</ul>
          </section>

          <section className="footer__column">
            <h3 className="footer__sub-header">Products</h3>
            <ul>{products.map(CreateLinkItem)}</ul>
          </section>

          <section className="footer__column">
            <h3 className="footer__sub-header">
              <a
                className="footer__link"
                href="https://www.brainstorminc.com/partner-program"
                target="_blank"
                rel="noreferrer noopener"
              >
                Partners
              </a>
            </h3>
            <ul>{partners.map(CreateLinkItem)}</ul>
          </section>

          <section className="footer__column">
            <h3 className="footer__sub-header">Resources</h3>
            <ul>{resources.map(CreateLinkItem)}</ul>
          </section>

          <section className="footer__column">
            <h3 className="footer__sub-header">Company</h3>
            <ul>{company.map(CreateLinkItem)}</ul>
          </section>

          <section className="footer__column">
            <h3 className="footer__sub-header">Compare</h3>
            <ul>
              <li>
                <a
                  className="footer__link"
                  href="https://www.brainstorminc.com/lms-vs-brainstorm"
                >
                  vs LMS
                </a>
                <br />
                <em className="footer__emphasized-text">
                  Learning management system
                </em>
              </li>
              <li>
                <a
                  className="footer__link"
                  href="https://www.brainstorminc.com/dap-vs-brainstorm"
                >
                  vs DAP
                </a>
                <br />
                <em className="footer__emphasized-text">
                  Digital adoption platform
                </em>
              </li>
            </ul>
          </section>
        </div>

        <div className="footer--bottom">
          <section>
            <span className="footer__copyright-text">
              Copyright @2022 BrainStorm, Inc. All Rights Reserved.
            </span>
          </section>

          <section>
            <span>
              <a
                className="footer__link"
                href="https://www.brainstorminc.com/legal"
                target="_blank"
                rel="noreferrer noopener"
              >
                Legal
              </a>

              <a
                className="footer__link"
                href="https://www.brainstorminc.com/legal#privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>

              <a
                className="footer__link"
                href="https://www.brainstorminc.com/legal#terms"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & conditions
              </a>
            </span>
          </section>

          <section>
            <a
              className="footer__icon-link"
              href="https://www.linkedin.com/company/brainstorm-inc"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={linkedinIcon} alt="BrainStorm LinkedIn page" />
            </a>
            <a
              className="footer__icon-link"
              href="https://twitter.com/brainstorminc"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={twitterIcon} alt="BrainStorm Twitter page" />
            </a>
            <a
              className="footer__icon-link"
              href="https://www.facebook.com/enduserexperts"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={facebookIcon} alt="BrainStorm Facebook page" />
            </a>
            <a
              className="footer__icon-link footer__icon-link--last"
              href="https://www.instagram.com/brainstormteam/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={instagramIcon} alt="BrainStorm Instagram page" />
            </a>
          </section>
        </div>
      </div>
    </footer>
  );
};
