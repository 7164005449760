import "./Content.scss";

import groupImage from "../../assets/images/Group(2).png";

export const Content = (): JSX.Element => {
  return (
    <div className="main-content">
      <img className="main-content__image" src={groupImage} alt="" />

      <h3 className="main-content__title">Meet BrainStorm Lite.</h3>

      <p className="main-content__text">
        BrainStorm Lite gives clients and their end users access to the
        BrainStorm software adoption platform, including core platform
        functionality and a rotating selection of our highest-impact learning
        flows.
      </p>

      <ul className="main-content__list">
        <li className="main-content__list-item">Onboard new users quickly</li>
        <li className="main-content__list-item">
          Intervene to encourage better user behavior
        </li>
        <li className="main-content__list-item">
          Speed up adoption of new technology, and
        </li>
        <li className="main-content__list-item">
          Drive end-user awareness of specific features.
        </li>
      </ul>
    </div>
  );
};
