import axios from "axios";
import { CreateAccountBody } from "../types/postRequests";

export function CreateAccount(body: CreateAccountBody) {
  return axios.post("api/create", body, {
    params: {
      client_id: sessionStorage.getItem("client_id"),
    },
  });
}
